<template>
	<!--begin::Mixed Widget 1-->
	<div class="card card-custom bg-gray-100 card-stretch">
		<!--begin::Header-->
		<div class="card-header border-0 bg-success py-5 pb-2">
			<v-icon class="card-drag-handle">mdi-drag</v-icon>
			<h3 class="card-title font-weight-bolder text-white">Leave Status</h3>
			<v-menu left offset-y :max-width="200">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						height="35"
						min-height="35"
						depressed
						color="green lighten-5"
						v-bind="attrs"
						v-on="on"
						:loading="countLoading"
					>
						<span class="fs-16 text-capitalize px-5">{{ dActiveLeaveFilter.text }}</span>
						<v-icon>mdi-menu-down</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="(item, index) in dLeaveDurationList"
						:key="index"
						@click="filterCount(item)"
						:class="[
							'py-1',
							{
								'grey lighten-2': dActiveLeaveFilter.value == item.value,
							},
						]"
					>
						<v-list-item-title>{{ item.text }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<!--end::Header-->
		<!--begin::Body-->
		<div class="card-body p-0 position-relative overflow-hidden">
			<!--begin::Chart-->
			<div
				id="kt_mixed_widget_1_chart"
				class="card-rounded-bottom bg-success dashboard-total-jobs"
				style="min-height: 100px"
			></div>
			<div class="card-spacer smallbxc_row">
				<!--begin::Row-->
				<div class="row m-0">
					<div class="col bg-light-primary ttbox rounded-xl mr-7">
						<span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
							<inline-svg :src="$assetURL('media/custom-svg/tickets.svg')" />
						</span>
						<div class="ttnumbr">
							<template v-if="countLoading">
								<v-progress-circular indeterminate color="primary"></v-progress-circular>
							</template>
							<template v-else>
								{{ dLeaves.allLeaves }}
							</template>
						</div>
						<router-link
							:to="{ name: 'dashboard' }"
							class="text-primary font-weight-semi-bold font-size-h6"
							>Total Leaves</router-link
						>
					</div>
					<div class="col bg-light-warning ttbox rounded-xl">
						<span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/ticket.svg')" />
							<!--end::Svg Icon-->
						</span>
						<div class="ttnumbr">
							<template v-if="countLoading">
								<v-progress-circular indeterminate color="warning"></v-progress-circular>
							</template>
							<template v-else>
								{{ dLeaves.pending_leaves }}
							</template>
						</div>
						<router-link
							:to="{ name: 'dashboard' }"
							class="text-warning font-weight-semi-bold font-size-h6"
							>Total Pending Leaves</router-link
						>
					</div>
				</div>
				<!--end::Row-->
				<!--begin::Row-->
				<div class="row m-0">
					<div class="col bg-light-success ttbox rounded-xl mr-7">
						<span class="svg-icon svg-icon-3x svg-icon-success d-block">
							<inline-svg :src="$assetURL('media/custom-svg/close-t.svg')" />
						</span>
						<div class="ttnumbr">
							<template v-if="countLoading">
								<v-progress-circular indeterminate color="success"></v-progress-circular>
							</template>
							<template v-else>
								{{ dLeaves.accepted_leave }}
							</template>
						</div>
						<router-link
							:to="{ name: 'dashboard' }"
							class="text-success font-weight-semi-bold font-size-h6"
							>Total Accepted Leaves</router-link
						>
					</div>
					<div class="col bg-light-danger ttbox rounded-xl">
						<span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
							<inline-svg :src="$assetURL('media/custom-svg/delete.svg')" />
						</span>
						<div class="ttnumbr">
							<template v-if="countLoading">
								<v-progress-circular indeterminate color="danger"></v-progress-circular>
							</template>
							<template v-else>
								{{ dLeaves.rejected_leave }}
							</template>
						</div>
						<router-link
							:to="{ name: 'dashboard' }"
							class="text-danger font-weight-semi-bold font-size-h6"
							>Total Rejected Leaves</router-link
						>
					</div>
				</div>
				<!--end::Row-->
			</div>
		</div>
	</div>
	<!--end::Mixed Widget 1-->
</template>
<script>
import { GET_LEAVES, SET_ACTIVE_FILTER } from "@/core/services/store/dashboard.module.js";
import { mapGetters } from "vuex";
export default {
	name: "LeaveCount",
	data() {
		return {
			countLoading: false,
		};
	},
	methods: {
		filterCount(item) {
			if (item.value != this.dActiveLeaveFilter.value) {
				this.$store.commit(SET_ACTIVE_FILTER, { key: "active_leave_filter", value: item });
				this.getLeaves();
			}
		},
		getLeaves() {
			this.countLoading = true;
			this.$store
				.dispatch(GET_LEAVES, {
					filter: this.dActiveLeaveFilter.value,
				})
				.finally(() => {
					this.countLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["dActiveLeaveFilter", "dLeaveDurationList", "dLeaves"]),
	},
	mounted() {
		//this.getLeaves();
	},
};
</script>
